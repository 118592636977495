<template>
  <div class="my-events">
    <div class='events__tabs'>
      <a-row  :gutter='25' style='margin-bottom: 1rem'>
        <a-col :xxl='16' :lg='16' :sm="15" :xs='24'>
          <a-row>
            <a-col :xxl='7' :lg='8' :sm="16" :xs='24' class="mt-3">
              <span  @click="onChangeTab('register')" :class="stateView.filter == 'register' ? 'selected': ''">Mis eventos registrados </span>
            </a-col>
            <a-col :xxl='7' :lg='6' :sm="8" :xs='24' class="mt-3">
              <span @click="onChangeTab('interest')" :class="stateView.filter == 'interest'? 'selected': ''">Me interesa</span>
            </a-col>
            <a-col :xxl='7' :lg='6' :sm="8" :xs='24' class="mt-3" v-if="stateView.roles.find(rol => rol.nombre === 'Responsable de Registro')">
              <span @click="onChangeTab('register_assistants')" :class="stateView.filter == 'register_assistants' ? 'selected': ''">Registro de asistencia</span>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xxl='8' :lg='8' :sm="9" :xs='24'>
          <a-input
            v-on:keyup.enter="handleChangeSearch($event)"
            placeholder="Buscar evento"
            class="input-field"
          >
            <template #prefix>
              <sdFeatherIcons type="search" />
            </template>
          </a-input>

        </a-col>
      </a-row>
    </div>
    <Main style="margin-top: 2rem; padding: 0 12.5px;">
      <RegisterOverview
      v-if="stateView.filter != 'register_assistants'"
      :section="stateView.filter"
      :search="stateView.search" />

      <RegisterAssistantsEventComponent :search="stateView.search" v-else />
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import { reactive } from 'vue';
import { defineAsyncComponent } from 'vue';
import { getItem } from '../../utility/localStorageControl';
import { removeSeconds } from '@/utility/utility';



const RegisterOverview = defineAsyncComponent(() => import('./overview/index/RegisterOverview'));
const RegisterAssistantsEventComponent = defineAsyncComponent(() => import('./overview/index/RegisterAssistansEvent'));

export default {
  name: 'RegisterEvents',
  components: {
    Main,
    RegisterOverview,
    RegisterAssistantsEventComponent
  },
  async setup() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', "Mis eventos");

    const stateView = reactive({
      filter : useRoute().query.view ? 'register_assistants' :  'register',
      search: '',
      roles: getItem('roles')
    });

    const onChangeTab = name =>{
      stateView.filter = name
      // getEvents();
    };

    const handleChangeSearch = e => {
      stateView.search = e.target.value;
    };

    return {
      onChangeTab,
      handleChangeSearch,
      stateView,
      removeSeconds
    };
  },
};
</script>
<style scoped lang="scss">
.my-events {
  padding: 40px 32px;
  margin: 0 auto;
}
.events__tabs {
  span {
    color: #989898;
    font-size: 14px;
    font-family: Nutmeg;
    font-weight: bold;
    padding-bottom: 5px;
    &.selected {
      color: #404040;
      font-family: Nutmeg;
      border-bottom: 3px solid #3FA7E1;
    }
    &:hover {
        cursor: pointer;
    }
  }
  .input-field {
    height: 5vh;
    border-radius: 20px;
    @media only screen and (max-width: 575px) {
      margin-top: 1rem;
    }
  }
}
.box-event {
  border: 1px solid #bcbdbe;
  border-radius: 7px;
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 6px;
  border: none;
}
.btn-add-event:hover {
  cursor: pointer;
}
.title-more-events {
  color: #e70e4c;
  font-size: 14px;
  font-weight: bold;
}
.title-more-events:hover {
  cursor: pointer;
}
</style>
